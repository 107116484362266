html { 
    background: url(../public/assets/images/site_bg.png) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-family: Roboto;
  }

a {
  color:white
}